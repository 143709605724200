import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { ViewService } from '@data-access/view/view.service'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { ToastService } from '../data-access/toast/toast.service'
import { StoreInfo } from '../models/home.model'
import { selectStoreInfo } from '../state-management/store/store-info.selector'
import { StoreInfoResolver } from '../resolver/store.resolver'

@Injectable()
export class ChooseOrderServiceGuard implements CanActivate {
  storeInfo$: Observable<StoreInfo> = this._store.select(selectStoreInfo)
  storeInfo$Subs: Subscription = new Subscription()

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private _toastService: ToastService,
    private _store: Store,
    private _viewService: ViewService,
    private _storeInfoResolver: StoreInfoResolver
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this._storeInfoResolver.resolve(route, state).subscribe({
        next: (storeInfo) => {
          console.log(storeInfo);

          if (storeInfo?.storeSlug) {
            const data = JSON.parse(localStorage.getItem(`${storeInfo.qr}`) ?? '{}')
            let orderService = ''
            if (data.hasOwnProperty('serviceType')) orderService = data.serviceType;
            let configService = [...storeInfo?.serviceType]
            // const indexOfTakeout = configService.indexOf('TAKEOUT');
            // if (indexOfTakeout !== -1) {
            //   // CHANGE TAKEOUT to PICK_UP
            //   configService[indexOfTakeout] = 'PICK_UP';
            // }
  
            // If order service in storage available in configService
            let orderServiceAvailable = configService.includes(orderService)
            if (orderServiceAvailable) {
              observer.next(true)
            }
  
            // If order service not available in storage and check configService length more than 1
            // then redirect to choose order service
            if (!orderServiceAvailable && configService?.length > 1) {
              if (route.queryParams['qr']) {
                this.router.navigate([`order-service`], {
                  queryParams: route.queryParams
                })
              }
              this._toastService.broadcast(
                'SHOW_TOAST',
                'SELECT_ORDER_SERVICE_FIRST'
              )
              observer.next(true)
            }
  
            // If Order Service not available in storage and configService length equals is 1
            // and then set storage and redirect to home
            if (!orderServiceAvailable && configService.length === 1) {
              this._viewService.setServiceType(
                configService[0],
                storeInfo?.storeSlug
              )
  
              if (route.queryParams['qr']) {
                this.router.navigate([`home`], {
                  queryParams: route.queryParams
                })
              }
              observer.next(true)
            }
  
            observer.next(true)
          }
  
          // Based on the logic of storeInfo, emit true or false
          observer.next(true); // or observer.next(false) based on your logic
  
          // Complete the observable to prevent any potential infinite loop
          observer.complete();
        },
        error: (err) => {
          console.error(err);
  
          // In case of error, you might want to emit false and complete
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
}
