import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ToastService } from '../data-access/toast/toast.service'
import { HttpError } from '../models/common'

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (res: any) => {},
        (error: any) => {
          switch (error?.status) {
            case HttpError?.BadRequest:
              if (error.error.error === 'MERCHANT_TABLE_QR_OPEN_BILL_PAID') {
                console.log(error.error.error)
                return
              }
              break
            case HttpError?.NotFound:
              if (error.error.error === 'MERCHANT_NOT_FOUND') {
                this.router.navigate(['/e/404'])
                this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
              } else if (
                error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE'
              ) {
                this.router.navigate([
                  '/e/table-management-requires-activation'
                ])
              }
              break

            case 422:
              if (error.error.error === 'ORDER_CART_EMPTY') {
                return
              }
            
              if (Object.keys(error.error.data).length > 0 && error.error.data['error-message'] === 'ORDER_HAS_BEEN_ALREADY_VOIDED') {
                this.router.navigate(['/e/please-scan-qr/order-has-been-voided'])
              }

              if (Object.keys(error.error.data).length > 0 && error.error.data['error-message'] === 'ORDER_HAS_BEEN_PAID') {
                this.router.navigate(['/e/please-scan-qr/order-has-been-paid'])
              }
              
              if (error.error.error === 'MERCHANT_TABLE_QR_SESSION_DEVICE_ID_NOT_VALID' && Object.keys(error.error.data).length === 0) {
                this.router.navigate(['/e/please-scan-new-qr'])
              }

              break

            case HttpError?.Forbidden:
              break

            case HttpError?.InternalServerError:
              break

            case HttpError?.Unauthorized:
              break

            case HttpError?.ServerDown:
              break
            default:
              this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
              return true
          }
          return throwError(() => new Error(error?.error?.error))
        }
      )
    )
  }
}
