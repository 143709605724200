import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { TimeZone } from '@models/utility.model'
import { Observable, catchError, map, of, tap } from 'rxjs'
import { Response } from '../../models/response'
import { formatDate } from '@angular/common'
import { WEEKDAY } from '@utils/const'

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public readonly platformId: string
  ) {}

  getPhoneCountries(): Observable<Response<PhoneCountries[]>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http
      .get<Response<PhoneCountries[]>>(`/api/utilities/country`, {
        headers: headers
      })
      .pipe(
        tap(response => {
          return response.data.filter(phoneCountry => {
            if (
              phoneCountry.code !== 'ID' &&
              phoneCountry.code !== 'SG' &&
              phoneCountry.code !== 'KH'
            ) {
              phoneCountry
            }
          })
        }),
        catchError(this.handleError<Response<PhoneCountries[]>>('getCountries'))
      )
  }

  /**
   *
   * @returns
   */
  getTimeZoneList(): Observable<Response<TimeZone[]>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http.get<Response<TimeZone[]>>('/api/utilities/timezone', {
      headers
    })
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }

  log(arg0: string) {
    console.log(arg0)
  }

  getServerTime(): Observable<Response<{serverTime: string}>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http.get<Response<{serverTime: string}>>('/server-time', {
      headers
    })
  }

  getDate(offset: number = 8) {
    return this.getServerTime().pipe(
      map((response) => {

        const dateStr = response.data.serverTime;

        // Parse the date string into a Date object
        const originalDate = new Date(dateStr);
    
        // Define the offset in hours (e.g., +2 hours)
        const offsetHours = offset;
    
        // Apply the offset
        const newDate = new Date(originalDate.getTime() + offsetHours * 60 * 60 * 1000);
    
        // Format the new date to a string
        const newDateStr = newDate.toUTCString();
        
        const current_time = this.convertTo24Hour(formatDate(newDateStr, 'hh:mm:dd a', 'en-US', `GMT${offset}`))

        return {
          serverTimeByOffset: newDateStr,
          current_time: current_time,
          serverTime: response.data.serverTime,
          day: WEEKDAY[newDate.getDay()],
          month: newDate.getMonth(),
          year: newDate.getFullYear(),
          timestamp: Date.parse(newDateStr)
        }
      })
    )
  }

  convertTo24Hour(time12h: string): string {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes, seconds] = time.split(':');
  
    if (modifier === 'PM' && hours !== '12') {
      hours = String(+hours + 12);
    }
    
    if (modifier === 'AM' && hours === '12') {
      hours = '00';
    }
  
    return `${hours}:${minutes}:${seconds}`;
  }
}

interface PhoneCountries {
  code: string
  name: string
  phone: string
}


export interface DateServerTime {
  serverTimeByOffset: string,
  current_time: string,
  serverTime: string,
  day: string,
  month: number,
  year: number
  timestamp: number
}