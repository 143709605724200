import { createReducer, on } from '@ngrx/store'
import { StoreInfo } from '../../models/home.model'
import { storeInfoUpdate } from './store-info.actions'

export const initialState: StoreInfo = {
  storeSlug: '',
  brandLogo: 'assets/images/logo/logo-ideku-black.png?12',
  bannerDesktop: [],
  bannerMobile: [],
  name: 'IDEFOOD',
  announcementEnable: false,
  announcementMessage: '',
  template: '',
  contacts: [],
  fontStyle: 'Poppins, sans-serif',
  colorPrimary: '#00489a',
  colorSecondary: '#c9e7ff',
  colorFont: '#404040',
  colorTextButtonPrimary: '#ffffff',
  colorTextButtonSecondary: '#00489a',
  shopClosed: false,
  shopOperationalOpenAt: 0,
  shopOperationalClosedAt: 0,
  shopHoliday: false,
  shopHolidayOpenAt: 0,
  shopHolidayClosedAt: 0,
  currency: '',
  country: '',
  configService: [],
  roundingModel: {
    roundingMethod: '',
    roundingNearest: '',
    pricingType: ''
  },
  serviceType: [],
  timeZone: '',
  operatingTiming: [],
  operatingTimingSelected: {
    day: '',
    dayIndex: 0,
    operationHoursModelList: [
      {
        timeStartAt: '',
        timeEndAt: ''
      }
    ],
    twentyFourHour: false
  },
  isLoading: true,
  merchantId: 0,
  storeId: 0,
  qr: '',
  isMerged: false,
  digitsInfo: '',
  isTableManagementActive: false,
  locale: ''
}

export const storeInfoReducer = createReducer(
  initialState,
  on(storeInfoUpdate, (state, storeInfo) => ({
    ...state,
    ...storeInfo
  }))
)
