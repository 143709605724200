import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ResetCheckout } from '@state-management/checkout/checkout-info.actions';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable, Subject, Subscription } from 'rxjs';
import { QRInfo } from '../../models/domain.model';
import { StoreInfo } from '../../models/home.model';
import { qrInfoUpdate } from '../../state-management/qr/qr-info.actions';
import { selectQRInfo } from '../../state-management/qr/qr-info.selector';
import { storeInfoUpdate } from '../../state-management/store/store-info.actions';
import { selectStoreInfo } from '../../state-management/store/store-info.selector';
import { ToastService } from '../toast/toast.service';
import { CartList } from '@models/cart.model';

@Injectable({
  providedIn: 'root'
})
export class ViewService implements OnDestroy {
  constructor(
    @Inject(PLATFORM_ID) public platformId: string,
    @Inject(DOCUMENT) public document: Document,
    private _cookieService: SsrCookieService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _toastService: ToastService,
    private _store: Store
  ) {
    this.isServer = isPlatformServer(platformId)
    this.isBrowser = isPlatformBrowser(platformId)

    this._activatedRoute.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams
    })
  }

  queryParams: any
  public isBrowser: boolean
  public isServer: boolean
  storeInfo$: Observable<StoreInfo> = this._store.select(selectStoreInfo)
  storeInfo$Subs: Subscription = new Subscription()
  storeInfo!: StoreInfo
  qrInfo$: Observable<QRInfo> = this._store.select(selectQRInfo)
  qrInfo$Subs: Subscription = new Subscription()
  qrInfo!: QRInfo
  public targetIdSelected = new Subject<string>();


  /**
   *
   * we have a method for saving data in web browser
   * SESSION STORAGE
   * LOCAL STORAGE
   * COOKIES STORAGE
   *
   */

  /**
   *
   * @sessionStorage
   * USING SESSION STORAGE
   *
   */

  setCurrentScrollData(value: any) {
    if (this.isBrowser) { 
      sessionStorage.setItem('currentScroll', JSON.stringify(value))
    }
  }

  getCurrentScrollData() {
    const storedValue = sessionStorage.getItem('currentScroll');
    return storedValue ? JSON.parse(storedValue) : null;
  }

  clearSessionAuthStorage() {
    if (this.isBrowser) {
      sessionStorage.removeItem('numberPhone')
      sessionStorage.removeItem('phoneCode')
      sessionStorage.removeItem('timeRemaining')
      sessionStorage.removeItem('verifyToken')
      sessionStorage.removeItem('verifyTokenExpired')
    }
  }

  resetSessionLayout() {
    if (this.isBrowser) {
      sessionStorage.setItem('productViewMode', 'mode-photo')
    }
  }

  email(email: string) {
    if (this.isBrowser) {
      localStorage.setItem('email', email)
    }
  }

  getLocalStorageByKey(key: string) {
    let result
    if (this.isBrowser) {
      result = localStorage.getItem(key)
    }
    return result
  }

  set(key: string, value: string) {
    if (this.isBrowser) {
      localStorage.setItem(key, value)
    }
  }

  remove(key: string) {
    if (this.isBrowser) {
      localStorage.removeItem(key)
    }
  }

  get(key: string) {
    let result
    if (this.isBrowser) {
      result = localStorage.getItem(key)
    }
    return result
  }

  logout(storeSlug: string) {
    if (this.isBrowser) {
      if (this.queryParams['qr']) {
        this._router.navigate([`/login`], {
          queryParams: this.queryParams
        })
      } else {
        this._router.navigate([`${storeSlug}/login`], {
          queryParams: this.queryParams
        })
      }
      this._toastService.broadcast('SHOW_TOAST', 'You have been logout')
      this.setHideSidebar()
      this._cookieService.delete('authToken', '/')
      this.clearSessionAuthStorage()
      this._store.dispatch(ResetCheckout())
    }
  }

  showSidebar() {
    let sidebar = this.document.querySelector('#sidebar')
    sidebar?.classList.add('show')
    sidebar?.classList.remove('hide')
  }

  setHideSidebar() {
    let sidebar = this.document.querySelector('#sidebar')
    sidebar?.classList.remove('show')
    sidebar?.classList.add('hide')
  }

  setStoreInfo(storeInfo: StoreInfo) {
    this._store.dispatch(storeInfoUpdate(storeInfo))
    this.set('storeInfo', JSON.stringify(storeInfo))
  }

  getStoreInfo() {
    this.storeInfo$Subs = this.storeInfo$.subscribe(result => {
      this.storeInfo = result
    })

    return this.storeInfo
  }

  setQRInfo(qrInfo: QRInfo) {
    this.set('qrInfo', JSON.stringify(qrInfo))
    this._store.dispatch(qrInfoUpdate(qrInfo))
  }

  getQRInfo() {
    this.qrInfo$Subs = this.qrInfo$.subscribe(result => {
      this.qrInfo = result
    })

    return this.qrInfo
  }

  setLanguage(code: string) {
    this.set('langCode', code)
  }

  getLanguage() {
    return this.get('langCode')
  }
  getOrderService(storeSlug: string): orderServiceStorage {
    let orderService = this.getServiceType(storeSlug)
    let result!: orderServiceStorage

    if (orderService === 'DINE_IN') {
      result = {
        orderService: 'DINE_IN',
        orderServiceAlias: 'DINE_IN'
      }
    } else if (orderService === 'TAKEOUT') {
      result = {
        orderService: 'TAKEOUT',
        orderServiceAlias: 'TAKEOUT'
      }
    } else if (orderService === 'DELIVERY') {
      result = {
        orderService: 'TAKEOUT',
        orderServiceAlias: 'DELIVERY'
      }
    }

    return result
  }

  copy(value: string) {
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = value
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
    this._toastService.broadcast('SHOW_TOAST', 'COPIED')
  }

  isLoggedIn() {
    return this._cookieService.check('authToken') ? true : false
  }

  setCheckoutField(field: string, value: any) {
    if (this.isBrowser) {
      sessionStorage.setItem(
        'checkoutField',
        JSON.stringify({
          ...JSON.parse(sessionStorage.getItem('checkoutField') || '{}'),
          [field]: value
        })
      )
    }
  }

  clearCheckoutField() {
    if (this.isBrowser) {
      sessionStorage.removeItem('checkoutField')
    }
  }

  getCheckoutField(field: string) {
    if (this.isBrowser) {
      return (
        JSON.parse(sessionStorage.getItem('checkoutField') || '{}')?.[field] ??
        ''
      )
    }
  }

  // Generic function for setting data in cookies
  setCookieMultiValue(key: string, value: string, storeSlugOrQr: string) {
    let cookieData = JSON.parse(this._cookieService.get(storeSlugOrQr) || '{}');
    cookieData[key] = value;
    this._cookieService.set(storeSlugOrQr, JSON.stringify(cookieData), 7, '/');
  }

  setLocalStorageMultiValue(key: string, value: string, storeSlugOrQr: string) {
    if (this.isBrowser) {
      let storageData = JSON.parse(localStorage.getItem(storeSlugOrQr) || '{}');
      storageData[key] = value;
      localStorage.setItem(storeSlugOrQr, JSON.stringify(storageData));
    }
  }

  // Generic function to get data from cookies
  getCookieMultiValue(key: string, storeSlugOrQr: string) {
    let cookieData = JSON.parse(this._cookieService.get(storeSlugOrQr) || '{}');
    return cookieData[key] || '';
  }

  getStorageMultiValue(key: string, storeSlugOrQr: string) {
    let storageData: any
    if (this.isBrowser) {
      storageData = JSON.parse(localStorage.getItem(storeSlugOrQr) || '{}');
    }
    return storageData?.[key] ?? '';
  }

  removeStorageMultiValue(key: string, storeSlugOrQr: string) {
      if (this.isBrowser) {
          let storageData = JSON.parse(localStorage.getItem(storeSlugOrQr) || '{}');

          if (storageData && key in storageData) {
              delete storageData[key];
              localStorage.setItem(storeSlugOrQr, JSON.stringify(storageData));
          }
      }
  }

  setDeviceId(deviceId: string, storeSlug: string) {
    this.setLocalStorageMultiValue('deviceId', deviceId, this.queryParams['qr'])
  }

  setServiceType(serviceType: string, storeSlug: string) {
    this.setLocalStorageMultiValue('serviceType', serviceType, this.queryParams['qr'])
  }

  setCustomerDeviceId(customerDeviceId: string, storeSlug: string) {
    this.setLocalStorageMultiValue('customerDeviceId', customerDeviceId, this.queryParams['qr'])
  }

  setQueueId(queueId: string, storeSlug: string) {
    this.setLocalStorageMultiValue('queueId', queueId, this.queryParams['qr'])
  }

  setStoreId(storeId: string, storeSlug: string) {
    this.setLocalStorageMultiValue('storeId', storeId, this.queryParams['qr'])
  }

  setPlaceOrderQueue(placeOrder: string, storeSlug: string) {
    this.setLocalStorageMultiValue('placeOrder', placeOrder, this.queryParams['qr'])
  }

  setAlreadyOrder(alreadyOrder: string, storeSlug: string) {
    this.setLocalStorageMultiValue('alreadyOrder', alreadyOrder, this.queryParams['qr'])
  }

  setCartListStorage(cart: CartList | any, storeSlug: string, type: string = 'ADD') {
    let cartList: CartList[] | any[] = this.getCartListStorage(storeSlug) ? JSON.parse(this.getCartListStorage(storeSlug)) : [];

    if (!cartList || cartList?.length === 0) cartList = []

    if (type === 'ADD') {
        // If cartList is empty or type is ADD, simply push the new cart item
        cartList.push(cart);
    } else if (type === 'UPDATE') {
        // If type is UPDATE, find the existing cart item and update it
        let cartIndex = cartList.findIndex((item: CartList) => item.productId === cart.productId); // Assuming each cart item has a unique identifier like productId
        if (cartIndex !== -1) {
            // If the cart item exists in the cartList, update it
            cartList[cartIndex] = cart;
        } else {
            // If the cart item doesn't exist in the cartList, push it as a new item
            cartList.push(cart);
        }
    } else if (type === 'DELETE') { 
      // Find the index of the cart item to delete
      let cartIndex = cartList.findIndex(item => item.productId === cart.productId); // Assuming each cart item has a unique identifier like productId
            
      if (cartIndex !== -1) {
          // If the cart item exists in the cartList, remove it
          cartList.splice(cartIndex, 1);
      }
    } else if (type === 'CLEAR_ALL') { 
      cartList = []
    }

    console.log("cartList", cartList)

    this.setLocalStorageMultiValue('cartList', JSON.stringify(cartList), this.queryParams['qr'])
  }

  getServiceType(storeSlug: string) {
    const localStorageServiceType = this.getStorageMultiValue('serviceType', this.queryParams['qr'])
    return localStorageServiceType
  }

  getDeviceId(storeSlug: string) {
    const localStorageDeviceId = this.getStorageMultiValue('deviceId', this.queryParams['qr'])
    return localStorageDeviceId
  }

  getCustomerDeviceId(storeSlug: string) {
    const localStorageCustomerDeviceId = this.getStorageMultiValue('customerDeviceId', this.queryParams['qr'])
    return localStorageCustomerDeviceId
  }

  getCartListStorage(storeSlug: string) {
    const localStorageCartList = this.getStorageMultiValue('cartList', this.queryParams['qr'])
    return localStorageCartList
  }

  getQueueId(storeSlug: string) {
    const localStorageQueueId = this.getStorageMultiValue('queueId', this.queryParams['qr'])
    return localStorageQueueId
  }

  getStoreId(storeSlug: string) {
    const localStorageStoreId = this.getStorageMultiValue('storeId', this.queryParams['qr'])
    return localStorageStoreId
  }

  getPlaceOrderQueue(storeSlug: string) {
    const localStoragePlaceOrder = this.getStorageMultiValue('placeOrder', this.queryParams['qr'])
    return localStoragePlaceOrder
  }

  getAlreadyOrder(storeSlug: string) {
    const localStorageAlreadyOrder = this.getStorageMultiValue('alreadyOrder', this.queryParams['qr'])
    return localStorageAlreadyOrder
  }
 


  /**
   *
   * @cookieService
   * USING COOKIES STORAGE
   *
   */

  ngOnDestroy(): void {
    this.storeInfo$Subs?.unsubscribe()
  }
}

interface orderServiceStorage {
  orderService: string
  orderServiceAlias: string
}
