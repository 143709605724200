import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable } from 'rxjs';

@Injectable()
export class AuthVerifyGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private _cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) public platformId: string,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      let authToken = this._cookieService.get('authToken');
      const key = route.queryParams['qr'] ?? route.params['storeSlug'];

      let serviceType = '';
      if (isPlatformBrowser(this.platformId)) {
        let serviceTypeLocalStorage = JSON.parse(localStorage.getItem(key) || '{}');
        serviceType = serviceTypeLocalStorage['serviceType'] ?? '';
      }

      if (!authToken && (serviceType === 'DINE_IN' || serviceType === 'TAKEOUT')) {
        observer.next(true);
        observer.complete();
      } else if (!authToken && (serviceType === 'DELIVERY')) {
        if (route.queryParams['qr']) {
          this.router.navigate(['/login'], {
            queryParams: route.queryParams,
          });
        }
        observer.next(false);
        observer.complete();
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  
}
