

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { DomainService } from '@data-access/domain/domain.service';
import { ViewService } from '@data-access/view/view.service';
import { HomeServiceImpl } from '@home/data-access/home.service-impl';
import { MicroSentryData } from '@utils/const';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataStorageService } from '../data-storage/data-storage.service';
import { LogService } from '../log/log.service';
import { QRInfo } from '@models/domain.model';
import { StoreInfo } from '@models/home.model';

@Injectable({
  providedIn: 'root',
})
export class StoreInfoResolver implements Resolve<StoreInfo | QRInfo> {
  constructor(
    private _domainService: DomainService,
    private _homeService: HomeServiceImpl,
    private _dataStorage: DataStorageService,
    private _router: Router,
    private _viewService: ViewService,
    private _logService: LogService,
    private _spinner: NgxSpinnerService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<any> {
    let storeSlug = route.params['storeSlug'] as string;
    this._dataStorage.setData('storeSlug-param', storeSlug);
    this._dataStorage.setData('queryParams', route.queryParams);
  
    if (!route.queryParams?.['qr']) {
      this._router.navigate(['/e/404']);
    }

    if (route.queryParams?.['qr']) {
      if (!this._dataStorage.getData('qrInfoResponse') || !this._dataStorage.getData('storeInfoResponse')) {
    
        // Get QR info
        return this._domainService.getQRInfo(route.queryParams?.['qr']).pipe(
          // Use switchMap to switch to the result of getStoreInfo with the obtained data
          switchMap((qrInfo) => {
            let qrInfoData = qrInfo.data;
            this._dataStorage.setData('qrInfoResponse', qrInfo);
            this._viewService.setQRInfo(qrInfoData)
      
            // Get store info using the dynamically obtained storeSlug
            return this._homeService.getStoreInfo(qrInfoData.storeSlug).pipe(
              tap((storeInfo) => {  
                let storeInfoData = storeInfo.data;
                storeInfoData.serviceType = qrInfoData.serviceType

                if (qrInfoData) {
                  Object.assign(storeInfoData, {
                    isAlreadyOrder: qrInfoData.isAlreadyOrder,
                    isOpenBill: qrInfoData.isOpenBill,
                    openBillId: qrInfoData.openBillId,
                    paxSizeSelected: qrInfoData.paxSizeSelected,
                    paxSizeMax: qrInfoData.paxSizeMax,
                    paxSizeChangeable: qrInfoData.paxSizeChangeable,
                    isDineInQR: qrInfoData.isDineInQR,
                    sessionDeviceId: qrInfoData.sessionDeviceId,
                    invoice: qrInfoData.invoice,
                    tableIds: qrInfoData.tableIds,
                    qrType: qrInfoData.qrType,
                    tableNumber: qrInfoData.tableNumber,
                    tableSplitNumber: qrInfoData.tableSplitNumber,
                    configService: qrInfoData.serviceType,
                    customerDeviceId: qrInfoData.customerDeviceId,
                    isReadOnly: qrInfoData.isReadOnly,
                    qr: route.queryParams?.['qr'],
                    openBillData: qrInfoData.openBillData,
                    isTableManagementActive: qrInfoData.isTableManagementActive,
                    storeSlug: qrInfoData.storeSlug,
                    isLoading: false
                  })
                }
                
                this._dataStorage.setData('storeInfoResponse', storeInfoData);
                this._viewService.setStoreInfo(storeInfoData)
              }),
              catchError((error) => {
                // Handle the error here as needed
                console.error('Error fetching Store info:', error);
      
                if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
                  this._router.navigate(['/e/table-management-requires-activation'])
                } else {
                  this._router.navigate(['/e/404'])
                }
        
                this._spinner.hide()
        
                this._logService.error({ 
                  type: 'ERROR',
                  merchantId: 0,
                  storeId: 0,
                  qr: route.queryParams?.['qr'],
                  qrType: '',
                  dateTime: `${new Date()}`,
                  body: JSON.stringify({
                    infoUser: {
                      event: MicroSentryData.StoreInfo.message,
                      storeName: '',
                      className: 'AppComponent',
                      methodName: 'getStoreInfo',
                      deviceId: this._viewService.getDeviceId(qrInfoData.storeSlug),
                      customerDeviceId: this._viewService.getCustomerDeviceId(qrInfoData.storeSlug),
                      serviceType: this._viewService.getServiceType(qrInfoData.storeSlug),
                      userAgent: navigator.userAgent,
                    },
                    data: error
                  })
                })
          
                if (error.error.status === 404) {
                  this._router.navigate(['/e/404']);
                }
          
                return throwError(() => error);
              })
            );
          }),
          catchError((error) => {
            // Handle the error here as needed
            console.error('Error fetching QR info:', error);
      
            if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
              this._router.navigate(['/e/table-management-requires-activation'])
            } else if (error.error.error === 'MERCHANT_TABLE_QR_NOT_FOUND') {
              this._router.navigate(['/e/404'])
            } else if (error.error.error === 'MERCHANT_TABLE_QR_EXPIRED') {
              this._router.navigate(['/e/qr-expired'])
            } else {
              this._router.navigate(['/e/404']);
            }
      
            // Optionally rethrow the error to propagate it further
            return throwError(() => error);
          })
        );
      } else {
        return of(this._dataStorage.getData('storeInfoResponse'))
      }
    } else  {
      return of(this._dataStorage.getData('storeInfoResponse'))
    }
  
  }
}
